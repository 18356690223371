import { LuMap } from 'react-icons/lu';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useCanvas, { TCoordinates } from '../../../hooks/useCanvas';
import MapControls from './MapControls';
import { useEffect } from 'react';

interface MapCardProps {
  imageSrc: string;
  coordinate: TCoordinates;
}

const MapCard = ({ imageSrc, coordinate }: MapCardProps) => {
  const { canvasRef, setCoordinates } = useCanvas({
    initialCoordinates: { x: 0, y: 0 },
    imageSrc: imageSrc || '',
  });

  useEffect(() => {
    setCoordinates(coordinate);
  }, [coordinate]);

  return (
    <div className="relative bg-white w-full p-[16px] rounded-2xl">
      <div className="flex flex-col gap-[12px]">
        {imageSrc ? (
          <TransformWrapper>
            <div className="relative flex-grow w-full overflow-hidden border-2 rounded-lg border-secondary">
              <TransformComponent>
                <canvas
                  ref={canvasRef}
                  width={580}
                  height={300}
                  className="block mx-auto max-w-full h-[300px] object-contain "
                />
              </TransformComponent>
            </div>
            <MapControls />
          </TransformWrapper>
        ) : (
          <div className="h-[300px] bg-gray_semidark -mx-[16px]">
            <div className="flex flex-col items-center justify-center h-full">
              <LuMap color="#BFBFBF" size={24} />
              <span className="text-text_gray">등록된 지도가 없습니다.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapCard;
