import workflowNodeJson from '../json/workflowNode.json';
import itemPreset from '../components/workflow/ItemPreset';

export const workflowParser = (
  nodes: any,
  edges: any,
  robotInfo: any,
  selectedUserAuthSite: string,
  type: string | null,
  robotType: string,
) => {
  const jsonData: any[] = [];

  let startEndId: string[] = [];

  nodes.forEach((item: any, idx: number) => {
    if (item.data.label.props.action === 'END') {
      startEndId.push(item.id);
    }
  });

  nodes.forEach((item: any, idx: number) => {
    if (item.data.label.props.type !== 'START/END') {
      const data = nodeParser(
        item,
        item.data.label.props.fullName,
        edges,
        robotInfo,
        robotType,
        selectedUserAuthSite,
        type,
        startEndId,
        idx,
      );
      jsonData.push(data);
    }
  });

  return jsonData;
};

export const nodeParser = (
  item: any,
  action: string,
  edges: any,
  robotInfo: any,
  robotType: string,
  selectedUserAuthSite: string,
  type: string | null,
  startEndId: string[],
  idx: number,
) => {
  const key = action.split('|');

  switch (key[1]) {
    case 'WAIT': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (robotType === 'single') {
        json.message.deviceId = robotInfo.deviceId;
      } else {
        json.message.deviceId = '';
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.wait_time = item.variable[0].value;
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }
    case 'ON_TIME': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (robotType === 'single') {
        json.message.deviceId = robotInfo.deviceId;
      } else {
        json.message.deviceId = '';
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.on_time = item.variable[0].value;
      }
      // const transition = [];
      // let cnt = 0;

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'MOVE': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.poiId = item.variable[0].value;
      }

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'CHARGE': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.action.param = {};

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }

    case 'CHANGE_MAP': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        json.message.action.param = {
          mapId: item.condition.mapValue.map,
          poseX: item.condition.mapValue.x,
          poseY: item.condition.mapValue.y,
          poseTheta: item.condition.mapValue.z,
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'HALL_CALL': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;
      }

      if (item.startingFloor !== undefined && item.targetFloor !== undefined) {
        json.message.action.param = {
          groupId: item.condition?.inputValue?.groupId,
          carId: item.condition?.inputValue?.carId,
          startingFloor: item.startingFloor[0].value,
          targetFloor: item.targetFloor[0].value,
          doorSide: 'FRONT',
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'CAR_CALL': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;
      }

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param = {
          groupId: item.condition?.inputValue?.groupId,
          carId: item.condition?.inputValue?.carId,
          targetFloor: item.variable[0].value,
          doorSide: 'FRONT',
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'CLEAN': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue?.alias;
      }

      if (
        item.task[0].data.siteId !== undefined &&
        item.task[0].data.mapId !== undefined &&
        item.task[0].data.taskId !== undefined
      ) {
        json.message.action.param = {
          executableTask: {
            siteId:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.siteId
                : null,
            siteName:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.siteName
                : null,
            mapId:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.mapId
                : null,
            mapName:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.mapName
                : null,
            taskId:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.taskId
                : null,
            taskName:
              item.task !== undefined && item.task !== null
                ? item.task[0].data.taskName
                : null,
          },
          cleaningMode:
            item.mode !== undefined && item.mode !== null
              ? item.mode[0].value
              : null,
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'OPEN_DOOR': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        json.message.action.param = {
          groupId: item.condition.inputValue.groupId,
          carId: item.condition.inputValue.carId,
          doorSide: 'FRONT',
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }
    case 'KEEP_OPEN': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        json.message.action.param = {
          groupId: item.condition.inputValue.groupId,
          carId: item.condition.inputValue.carId,
          doorSide: 'FRONT',
        };
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }

    case 'OUT_CONTROL': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        if (
          item.condition.conditionList !== undefined &&
          item.condition.conditionList !== null
        ) {
          json.message.action.param = item.condition.conditionList;
        }
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }

    case 'STOP':
    case 'APP_STOP':
    case 'BACK_TO_SCHEDULE':
    case 'HOME': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }

    case 'APP_CONTROL': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        json.message.action.param = item.condition.inputValue.appControl;
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;

      break;
    }

    case 'CHANGE_FLOOR': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.variable !== null && item.variable !== undefined) {
        json.message.action.param.currentFloor = item.variable[0].value;
      }

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.inputValue.alias;
      }

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'ROBOT ELV MOVE': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.action.param = {
        deviceIdElv: item.selectElv ? item.selectElv[0].value : null,
        deviceIdRobot:
          robotType === 'single'
            ? robotInfo.deviceId
            : item.multipleDevice.deviceId,
        insidePoi: item.insidePoi ? item.insidePoi[0].value : null,
        outPoi: item.outPoi ? item.outPoi[0].value : null,
        startingFloor: item.startingFloor ? item.startingFloor[0].value2 : null,
        targetFloor: item.targetFloor ? item.targetFloor[0].value2 : null,
        elvStartingFloor: item.startingFloor
          ? item.startingFloor[0].value1
          : null,
        elvTargetFloor: item.targetFloor ? item.targetFloor[0].value1 : null,
      };

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'CONVEYOR CONTROL': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'CHECK': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));
      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.condition !== undefined && item.condition !== null) {
        json.message.action_name_alias = item.condition.commonValue.alias;

        json.message.action.time_out = item.condition.commonValue.timeout;

        if (item.condition.conditionType === 'simple') {
          json.message.action.param.type = 'simple';
          json.message.action.param.conditions = [];

          if (
            item.condition.conditionList !== null &&
            item.condition.conditionList !== undefined
          ) {
            let conditionList: any = [];

            if (item.condition.conditionList.length > 0) {
              item.condition.conditionList.forEach((data: any, i: number) => {
                conditionList.push({
                  name: data.name,
                  operator: data.operator,
                  value: data.value,
                });
              });
            }

            json.message.action.param.conditions = conditionList;
          }
        } else {
          json.message.action.param.type = 'custom';
          json.message.action.param.names = item.condition.conditionList;
          json.message.action.param.custom_conditions =
            item.condition.inputValue.customValue;
        }
      }

      json.message.transition = transitionData(item, edges, startEndId);
      return json;
      break;
    }

    case 'CHECK_SELECT': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = item.multipleDevice.deviceId;
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.conditionCommon !== undefined && item.conditionCommon !== null) {
        json.message.action_name_alias = item.conditionCommon.alias;

        json.message.action.time_out = item.conditionCommon.timeout;
      }

      let conditionArray: any = [];

      if (item.condition !== undefined && item.condition !== null) {
        item.condition.forEach((data: any, i: number) => {
          if (data.conditionType === 'simple') {
            let conditionList: any = [];

            if (
              data.conditionList !== null &&
              data.conditionList !== undefined
            ) {
              if (data.conditionList.length > 0) {
                data.conditionList.forEach((data: any, i: number) => {
                  conditionList.push({
                    name: data.name,
                    operator: data.operator,
                    value: data.value,
                  });
                });
              }
            }

            conditionArray.push({
              type: data.conditionType,
              conditions: conditionList,
              optionSelect: data.optionSelect,
            });
          } else {
            conditionArray.push({
              type: data.conditionType,
              names: data.conditionList,
              custom_conditions: data.inputValue.customValue,
              optionSelect: data.optionSelect,
            });
          }
        });
      }

      json.message.action.param = conditionArray;

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    case 'USER_SELECT': {
      const nodeJson = workflowNodeJson[key[1]];
      const json = JSON.parse(JSON.stringify(nodeJson));

      if (type === 'TASK' || type === 'COMMAND') {
        if (robotType === 'single') {
          json.message.deviceId = robotInfo.deviceId;
        } else {
          json.message.deviceId = '';
        }
      }

      json.message.command_name = item.id;
      json.message.device_type = key[0];

      if (item.conditionCommon !== undefined && item.conditionCommon !== null) {
        json.message.action_name_alias = item.conditionCommon.alias;

        json.message.action.time_out = item.conditionCommon.timeout;
      }

      let conditionArray: any = [];

      if (item.condition !== undefined && item.condition !== null) {
        item.condition.forEach((data: any, i: number) => {
          if (data.conditionType === 'simple') {
            let conditionList: any = [];

            if (
              data.conditionList !== null &&
              data.conditionList !== undefined
            ) {
              if (data.conditionList.length > 0) {
                data.conditionList.forEach((data: any, i: number) => {
                  conditionList.push({
                    name: data.name,
                    operator: data.operator,
                    value: data.value,
                  });
                });
              }
            }

            conditionArray.push({
              type: data.conditionType,
              conditions: conditionList,
              optionSelect: data.optionSelect,
            });
          } else {
            conditionArray.push({
              type: data.conditionType,
              names: data.conditionList,
              custom_conditions: data.inputValue.customValue,
              optionSelect: data.optionSelect,
            });
          }
        });
      }

      json.message.action.param = conditionArray;

      json.message.transition = transitionData(item, edges, startEndId);

      return json;
      break;
    }

    default: {
      break;
    }
  }
};

const transitionData = (item: any, edges: any, startEndId: string[]) => {
  const transition = [];
  let cnt = 0;
  edges.forEach((data: any, idx: number) => {
    if (data.source === item.id) {
      cnt++;
      transition.push({
        trigger: 'next',
        source: data.source,
        dest: startEndId.some((el) => el === data.target)
          ? 'complete'
          : data.target,
      });
    }
  });

  if (cnt === 0) {
    transition.push({
      trigger: 'next',
      source: item.id,
      dest: 'complete',
    });
  }

  return transition;
};

export const actionIndexParser = (nodeList: any, edgeList: any) => {
  const copyNodes = JSON.parse(JSON.stringify(nodeList));
  const copyEdges = JSON.parse(JSON.stringify(edgeList));
  // const copyJsonData = JSON.parse(JSON.stringify(json));

  const start = copyNodes.filter((item: any) => item.type === 'input');
  const targetList: any[] = [start[0].id];
  const newNodes: any[] = [];

  for (const data of targetList) {
    for (const item of copyEdges) {
      if (item.source === data) {
        targetList.push(item.target);
      }
    }
  }

  targetList.forEach((item: any, i: number) => {
    const filterData = copyNodes.filter((data: any) => data.id === item);
    newNodes.push(filterData[0]);
  });

  // let checkSelectId: string | null = null;
  // let preCheckSelectId: string | null = null;
  // let checkSelectCnt: number = 0;
  //
  // if (newNodes.length > 0) {
  //     newNodes.forEach((item: any, i: number) => {
  //         item.data.label.props.action === 'CHECK_SELECT' ? preCheckSelectId = item.id : null;
  //
  //         item.id = `action${i + 1}`;
  //
  //         if (item.data.label.props.action === 'CHECK_SELECT') {
  //             checkSelectId = item.id;
  //         }
  //
  //     });
  // }
  //
  // const copy2Edges = JSON.parse(JSON.stringify(edgeList));
  // const checkSelectLine = copy2Edges.filter((el: any) => el.source === preCheckSelectId);
  //
  // checkSelectCnt = checkSelectLine.length;
  //
  // let sourceNum = 1;
  //
  // copyEdges.forEach((item: any, idx: number) => {
  //     if (checkSelectId !== null) {
  //
  //         if (item.source === preCheckSelectId) { //순서대로 노드번호를 수정 후 check_select 노드 다음 cnt수 만큼...
  //             item.source = checkSelectId;
  //
  //             const checkSelectNum = Number(checkSelectId.replace('action', ''));
  //             item.target = `action${checkSelectNum + sourceNum}`;
  //
  //             if (sourceNum < checkSelectCnt) {
  //                 sourceNum++;
  //             }
  //         } else {
  //             item.source = `action${idx + 1}`;
  //             item.target = `action${idx + 2}`;
  //         }
  //
  //     }
  //
  // });
  //
  // const copy3Edges = JSON.parse(JSON.stringify(copyEdges));
  // const checkSelectList = copy3Edges.filter((el: any) => el.source === checkSelectId);
  //
  // let targetNum = 1;
  // copy3Edges.forEach((item: any, idx: number) => {
  //     if (checkSelectId !== null) {
  //         const actionNum = Number(item.source.replace('action', ''));
  //         const checkSelectNum = Number(checkSelectId.replace('action', ''));
  //     }
  // });

  const newNewNodes = JSON.parse(JSON.stringify(newNodes));
  const newNewEdges = JSON.parse(JSON.stringify(copyEdges));

  return { newNewNodes, newNewEdges };
};
