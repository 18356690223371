import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RobotPage from './pages/RobotPage';
import StreamviewPage from './pages/Streamview';
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import useLoginStore from './store/loginStore';
import SignUp from './pages/SignUp';
import Verification from './pages/Verification';
import UserPage from './pages/UserPage';
import useUserStore from './store/userStore';
import useRobotstore from './store/robotStore';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from './config/firebase';
import DashboardPage from './pages/DashboardPage';
import TotalPage from './pages/TotalPage';
import useDashboardStore from './store/dashboardStore';
import { Permission } from './_types/user';
import { FirestoreCollenctions, Pathname } from './_types';
import SchedulePage from './pages/SchedulePage';
import WorkflowPage from './pages/WorkflowPage';
import BookmarkPage from './pages/BookmarkPage';
import IntroducePage from './pages/IntroducePage';
import LayoutWrapper from './components/layout/LayoutWrapper';
import NotFound from './pages/NotFound';
import HistoryPage from './pages/HistoryPage';

declare global {
  interface Window {
    naver: any;
  }
}

function App() {
  const { pathname } = useLocation();
  const {
    isLoggedIn,
    updateIsLoggedIn,
    changeUserInfo,
    updateIsShowLoginModal,
  } = useLoginStore();

  const {
    userStatus,
    updateIsShowUserIcon,
    changeUserStatus,
    changeSelectedUserAuthSites,
  } = useUserStore();
  const {
    changeSelectedRobot,
    changeSelectedTask,
    changeSelectedTaskDetailData,
  } = useRobotstore();
  const { changeCurrentDate, changeOneMonthAgoDate, changeOneYearAgoDate } =
    useDashboardStore();
  const navigate = useNavigate();

  const getUserStatus = async (user: any) => {
    const docRef = doc(db, FirestoreCollenctions.USERS, user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      changeUserStatus(docSnap.data().permission);
      if (docSnap.data().permission === Permission.SUPER_ADMIN) {
        localStorage.setItem(
          'hasAuthSite',
          docSnap.data().auth_sites.length > 0 ? 'true' : 'true',
        );
        localStorage.setItem(
          'hasAuthRobot',
          docSnap.data().auth_robots.length > 0 ? 'true' : 'true',
        );
        return;
      }
      localStorage.setItem(
        'hasAuthSite',
        docSnap.data().auth_sites.length > 0 ? 'true' : 'false',
      );
      localStorage.setItem(
        'hasAuthRobot',
        docSnap.data().auth_robots.length > 0 ? 'true' : 'false',
      );
    }
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const oneYearAgoMonth = month === 12 ? 11 : month + 1;
    const oneMonthAgoMonth = month === 1 ? 12 : month - 1;
    const day = today.getDate();

    changeCurrentDate(
      `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
        2,
        '0',
      )}`,
    );
    changeOneMonthAgoDate(
      `${year}-${String(oneMonthAgoMonth).padStart(2, '0')}-01`,
    );
    changeOneYearAgoDate(
      `${year - 1}-${String(oneYearAgoMonth).padStart(2, '0')}-01`,
    );

    if (window.location.search.indexOf('_gl') !== -1) {
      const url = new URL(window.location.href);
      url.searchParams.delete('_gl');
      window.history.replaceState({}, '', url.toString());
    }
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          localStorage.setItem('login', 'true');
          localStorage.setItem('verified', 'true');
          updateIsLoggedIn(true);
          changeUserInfo(user);
          getUserStatus(user);
        } else if (!user.emailVerified) {
          localStorage.setItem('login', 'true');
          localStorage.setItem('verified', 'false');
        }
      } else {
        changeUserStatus(Permission.USER);
        localStorage.setItem('login', 'false');
        localStorage.setItem('verified', 'false');
      }
    });
  }, []);

  useEffect(() => {
    updateIsShowUserIcon(false);
    changeSelectedRobot(null);
    changeSelectedTask(null);
    changeSelectedTaskDetailData(null);
    changeSelectedUserAuthSites([]);
  }, [pathname]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'production' && pathname === '/') {
      navigate('/total');
    }
    if (localStorage.verified === 'false') {
      if (
        pathname === '/dashboard' ||
        pathname === '/robot' ||
        pathname === '/schedule' ||
        pathname === '/workflow' ||
        pathname === '/bookmark' ||
        pathname === '/history'
      ) {
        updateIsShowLoginModal(true);
      } else {
        updateIsShowLoginModal(false);
      }
    } else {
      updateIsShowLoginModal(false);
    }
  }, [isLoggedIn, pathname]);

  useEffect(() => {
    if (pathname === Pathname.SIGNUP) {
      localStorage.verified === 'true' && navigate(Pathname.MAIN);
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('#login')) updateIsShowLoginModal(true);
  }, [window.location.href]);

  // useEffect(() => {
  //   // TODO: 스케줄, 워크플로우, 즐겨찾기, 알림이력 개발 전까지
  //   if (process.env.REACT_APP_ENV !== "production") return;
  //
  //   if (
  //     pathname === "/schedule" ||
  //     pathname === "/workflow" ||
  //     pathname === "/bookmark"
  //   )
  //     navigate("/");
  // }, [pathname]);

  return (
    <>
      {Object.values(Pathname).includes(pathname as Pathname) && (
        <LayoutWrapper />
      )}
      <Routes>
        {process.env.REACT_APP_ENV === 'production' ? (
          <Route path={Pathname.MAIN} element={<IntroducePage />} />
        ) : (
          <Route path={Pathname.MAIN} element={<TotalPage />} />
        )}
        <Route path={Pathname.TOTAL} element={<TotalPage />} />
        <Route path={Pathname.STREAMVIEW} element={<StreamviewPage />} />
        <Route path={Pathname.DASHBOARD} element={<DashboardPage />} />
        <Route path={Pathname.ROBOT} element={<RobotPage />} />
        <Route path={Pathname.SIGNUP} element={<SignUp />} />
        <Route path={Pathname.VERIFICATION} element={<Verification />} />
        <Route path={Pathname.USERPAGE} element={<UserPage />} />
        <Route path={Pathname.HISTORY} element={<HistoryPage />} />
        <Route path={Pathname.SCHEDULE} element={<SchedulePage />} />
        <Route path={Pathname.NOTFOUND} element={<NotFound />} />
        <Route path={Pathname.WORKFLOW} element={<WorkflowPage />} />
        <Route path={Pathname.BOOKMARK} element={<BookmarkPage />} />
      </Routes>

      <ToastContainer
        position="top-center"
        transition={Slide}
        autoClose={2000}
        pauseOnFocusLoss
        hideProgressBar
        draggable
        theme="colored"
      />
    </>
  );
}

export default App;
