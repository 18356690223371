import React, { useEffect, useState } from 'react';
import { IoCaretForwardCircle, IoStopCircle } from 'react-icons/io5';
import { FaElevator, FaListCheck, FaUserCheck } from 'react-icons/fa6'; //car call
import {
  MdCancelScheduleSend,
  MdEventRepeat,
  MdHome,
  MdLibraryAddCheck,
  MdOutlineCleaningServices,
  MdOutlineDoorSliding,
  MdOutlineStopScreenShare,
  MdPermMedia,
} from 'react-icons/md';
import { BiDoorOpen, BiSolidTimer } from 'react-icons/bi';
import { LuMove } from 'react-icons/lu';
import { PiElevatorFill } from 'react-icons/pi'; //hall call
import {
  RiBattery2ChargeFill,
  RiDoorClosedLine,
  RiRemoteControlLine,
} from 'react-icons/ri';
import { BsDatabaseFillCheck, BsSignStopFill } from 'react-icons/bs';

import { DatePicker, Input, Select } from 'antd';

import dayjs, { Dayjs } from 'dayjs';

import {
  TbEaseInOutControlPoints,
  TbEaseOutControlPoint,
  TbStairsUp,
} from 'react-icons/tb';
import { AiFillSchedule, AiOutlineStop } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { FcSerialTasks } from 'react-icons/fc';
import { AuthType } from '../../_types';
import useWorkflowStore from '../../store/workflowStore';
import useRestRobotData from '../../hooks/useRestRobotData';
import { getCmdList } from '../../_api/rest/common';
import errorPage from '../../pages/ErrorPage';
import '../../style/workflow.css';
import { getSiteRobotList } from '../../_api/rest/workflow';
import useUserStore from '../../store/userStore';

const actionList = {
  oneValue: [
    'WAIT',
    'ON_TIME',
    'CHARGE',
    'CHECK',
    'CHECK_SELECT',
    'CHANGE_MAP',
    'UPDATE_FLOOR',
    'HOME',
    'BACK_TO_SCHEDULE',
    'OPEN_DOOR',
    'KEEP_OPEN',
    'AUTO_DOOR_OPEN',
    'AUTO_DOOR_CLOSE',
    'IO_CONTROL',
    'CONVEYOR CONTROL',
    'APP_CONTROL',
    'OUT_CONTROL',
    'APP_STOP',
    'STOP',
  ],
  twoValue: ['MOVE', 'CAR_CALL', 'CHANGE_FLOOR'],
  threeValue: ['HALL_CALL', 'CLEAN'],
  presetValue: ['ROBOT ELV MOVE'],
};

export const NodeLabelStyle = (type: any, action: any) => {
  const style = {
    style: {
      width: '130px',
      height: '40px',
      padding: 7,
      fontSize: '19px',
      background: '#fff',
      cursor: 'move',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, 0.35)',
      fontFamily: 'Gmarket Sans, Medium',
      boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.4)',
    },
  };

  if (actionList.oneValue.includes(action)) {
    style.style.height = '50px';
    style.style.width = '180px';
  }
  if (actionList.twoValue.includes(action)) {
    style.style.height = '70px';
    style.style.width = '180px';
  }
  if (actionList.threeValue.includes(action)) {
    style.style.height = '85px';
    style.style.width = '180px';
  }
  if (actionList.presetValue.includes(action)) {
    style.style.height = '120px';
    style.style.width = '180px';
  }

  return style;
};

let newNode: any = [];
export const NodeDataChange = (newNodeData: any) => {
  newNode = newNodeData;
};

export const NodeLabelList = (
  props: any,
  id: string,
  fullName: string,
  type: string,
) => {
  const [userData, setUserData] = useState<AuthType | null>(null);

  const {
    robotInfo,
    robotCmdList,
    nodeList,
    edgeList,
    elvList,
    setElvList,
    changeNodeList,
    changeEdgeList,
    changeRobotInfo,
    changeRobotCmdList,
    robotType,
  } = useWorkflowStore();

  const { selectedUserAuthSite } = useUserStore();

  const { robotData } = useRestRobotData(userData);

  const [inputData, setInputData] = useState<string>('');
  const [changeFloorInputData, setChangeFloorInputData] = useState<string>('');

  const [elevatorList, setElevatorList] = useState<any>([]); //엘리베이터 목록

  const [selectRobot, setSelectRobot] = useState<any>({});

  const [selectRobotCmdList, setSelectRobotCmdList] = useState<any>([]);
  const [selectElvCmdList, setSelectElvCmdList] = useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [workDateStr, setWorkDateStr] = useState<string | string[] | null>(
    null,
  );
  const [workDate, setWorkDate] = useState<Dayjs | null>(null);
  const [moveList, setMoveList] = useState<any>([]); //COMMAND_MOVE
  const [cleanTaskList, setCleanTaskList] = useState<any>([]); //COMMAND_CLEAN
  const [cleanModeList, setCleanModeList] = useState<any>([]); //COMMAND_CLEAN
  const [elvHcFloorList, setElvHcFloorList] = useState<any>([]); //COMMAND_HALL_CALL
  const [elvCcFloorList, setElvCcFloorList] = useState<any>([]); //COMMAND_CAR_CALL
  const [changeFloorList, setChangeFloorList] = useState<any>([]); //COMMAND_CAR_CALL

  const [move, setMove] = useState<string | null>(null);
  const [changeFloor, setChangeFloor] = useState<string>('');
  const [cleaningMode, setCleaningMode] = useState<string | null>(null);
  const [cleaningTask, setCleaningTask] = useState<string | null>(null);
  const [hcFrom, setHcFrom] = useState<string | null>(null);
  const [hcTo, setHcTo] = useState<string | null>(null);
  const [ccTo, setCcTo] = useState<string | null>(null);
  const [selectElvDevice, setSelectElvDevice] = useState<string>('');

  ///PRESET
  const [selectPresetElv, setSelectPresetElv] = useState<string>('');
  const [inMoveList, setInMoveList] = useState<any>([]);
  const [outMoveList, setOutMoveList] = useState<any>([]);
  const [selectInMove, setSelectInMove] = useState<string>('');
  const [selectOutMove, setSelectOutMove] = useState<string>('');
  const [elvPresetFloorList, setElvPresetFloorList] = useState<any>([]);
  const [presetElvHcList, setPresetElvHcList] = useState<any>([]);
  const [presetElvCcList, setPresetElvCcList] = useState<any>([]);

  const [selectPresetFrom, setSelectPresetFrom] = useState<string>('');
  const [selectPresetTo, setSelectPresetTo] = useState<string>('');

  const [resultNodeList, setResultNodeList] = useState<any>([]);

  //로봇타입
  const [selectRobotType, setSelectRobotType] = useState<string>('');

  //다중 로봇일때, 디바이스 선택 -> 로봇 리스트
  const [multipleRobotList, setMultipleRobotList] = useState<any>([]);
  const [multipleSelectRobot, setMultipleSelectRobot] = useState<string | null>(
    null,
  );

  //preset 일때, 디바이스/엘리베이터
  const [presetRobotList, setPresetRobotList] = useState<any>([]);
  const [presetSelectRobot, setPresetSelectRobot] = useState<string>('');

  // preset 디바이스 목록 호출
  useEffect(() => {
    if (props.type === 'PRESET') {
      (async () => {
        await getRobotListApi(selectedUserAuthSite[0]);
      })();
    }
  }, [selectedUserAuthSite[0]]);

  useEffect(() => {
    if (robotType === 'single' && robotInfo) {
      (async () => {
        await getCmdListAPI(robotInfo.deviceId);
      })();
    }
  }, [robotType, robotInfo]);

  useEffect(() => {
    if (
      robotType === 'multiple' &&
      props.type !== 'COMMON' &&
      props.type !== 'START/END'
    ) {
      if (props.item !== undefined && props.item !== null) {
        if (props.saveType === 'TASK') {
          setMultipleSelectRobot(props.multipleSelectRobot.deviceId);
        }
      }
    }
  }, [props.item]);

  useEffect(() => {
    setMultipleRobotList(props.multipleRobotList);
  }, [props.multipleRobotList]);

  useEffect(() => {
    let elvList = [];

    if (presetRobotList.length > 0) {
      elvList = presetRobotList.filter(
        (item: any, idx: number) => item.modelType === '엘리베이터',
      );
    }

    setElevatorList(elvList);
  }, [presetRobotList]);

  useEffect(() => {
    changeNodeList(nodeList);
  }, [nodeList]);

  useEffect(() => {
    setSelectRobot(robotInfo);
    setSelectRobotCmdList(robotCmdList);

    console.log('robotCmdList = ', robotCmdList);

    /* 청소시작 변수 */
    setCleaningTask(null);
    setCleaningMode(null);

    /* 목적지 */
    setMove(null);
  }, [robotInfo, robotCmdList]);

  const getCmdListAPI = async (deviceId: string) => {
    await getCmdList(deviceId)
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          setSelectElvCmdList(res);
        }
      })
      .catch((error: any) => {
        errorPage();
      });
  };

  const getMultipleCmdListApi = async (deviceId: string) => {
    await getCmdList(deviceId)
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          // changeRobotCmdList(res);
          setSelectRobotCmdList(res);
        }
      })
      .catch((error: any) => {
        errorPage();
      });
  };

  const getRobotListApi = async (siteId: string) => {
    await getSiteRobotList(siteId)
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          if (res.length > 0) {
            res.forEach((item: any, i: number) => {
              item['value'] = item.deviceId;
              item['label'] = item.name;
            });
          }

          setPresetRobotList(res);
        }
      })
      .catch((error: any) => {
        errorPage();
      });
  };

  /* TASK 불러오기 */
  useEffect(() => {
    if (props.item !== undefined) {
      switch (props.action) {
        case 'WAIT': {
          if (props.item.variable !== undefined) {
            setInputData(props.item.variable[0].value);
          }
          break;
        }
        case 'ON_TIME': {
          if (props.item.variable !== undefined) {
            setWorkDate(dayjs(props.item.variable[0].value, 'YYYY-MM-DD'));
            setWorkDateStr(props.item.variable[0].value);
          }
          break;
        }
        case 'MOVE': {
          if (props.item.variable !== undefined) {
            if (robotType === 'multiple') {
              (async () => {
                await getMultipleCmdListApi(props.item.multipleDevice.deviceId);
              })();
            } else {
              (async () => {
                await getCmdListAPI(robotInfo.deviceId);
              })();
            }

            setMove(props.item?.variable[0]?.value);
          }
          break;
        }
        case 'HALL_CALL': {
          if (props.item.selectElv !== undefined && robotType === 'single') {
            (async () => {
              await getCmdListAPI(props.item.selectElv[0].value);

              setSelectElvDevice(props.item.selectElv[0].value);
            })();
          }

          if (
            props.item.multipleDevice !== undefined &&
            robotType === 'multiple'
          ) {
            (async () => {
              await getCmdListAPI(props.item.multipleDevice.deviceId);

              setSelectElvDevice(props.item.multipleDevice.deviceId);
            })();
          }

          ///////////////////////////////////////////////////
          if (props.item.startingFloor !== undefined) {
            setHcFrom(props.item.startingFloor[0].value);
          }
          if (props.item.startingFloor !== undefined) {
            setHcTo(props.item.targetFloor[0].value);
          }
          break;
        }
        case 'CAR_CALL': {
          /*if (props.item.selectElv !== undefined) {
              (async () => {
                await getCmdListAPI(props.item.selectElv[0].value);

                setSelectCcElv(props.item.selectElv[0].value);
              })();
            }*/

          if (props.item.selectElv !== undefined && robotType === 'single') {
            (async () => {
              await getCmdListAPI(props.item.selectElv[0].value);

              setSelectElvDevice(props.item.selectElv[0].value);
            })();
          }

          if (
            props.item.multipleDevice !== undefined &&
            robotType === 'multiple'
          ) {
            (async () => {
              await getCmdListAPI(props.item.multipleDevice.deviceId);

              setSelectElvDevice(props.item.multipleDevice.deviceId);
            })();
          }

          if (props.item.variable !== undefined) {
            setCcTo(props.item.variable[0].value);
          }

          break;
        }
        case 'CLEAN': {
          if (props.item.task !== undefined || props.item.mode !== undefined) {
            if (robotType === 'multiple') {
              (async () => {
                await getMultipleCmdListApi(props.item.multipleDevice.deviceId);
              })();
            } else {
              (async () => {
                await getCmdListAPI(robotInfo.deviceId);
              })();
            }
          }

          if (props.item.task !== undefined) {
            setCleaningTask(props.item.task[0].value);
          }
          if (props.item.mode !== undefined) {
            setCleaningMode(props.item.mode[0].value);
          }
          break;
        }
        case 'CHANGE_FLOOR': {
          // setSelectCcElv(props.item.variable[0].value);
          // setChangeFloor(props.item.variable[1].value);
          //
          // getCmdListAPI(props.item.variable[0].value);

          if (props.item.variable !== undefined) {
            setChangeFloorInputData(props.item.variable[0].value);
          }
          break;
        }
        case 'ROBOT ELV MOVE': {
          if (props.item.selectElv !== undefined) {
            (async () => {
              await getCmdListAPI(props.item.selectElv[0].value);
              setSelectPresetElv(props.item.selectElv[0].value);
            })();
          }

          if (
            props.item.multipleDevice !== undefined &&
            props.item.multipleDevice !== null &&
            props.saveType !== 'USERSET'
          ) {
            (async () => {
              await getMultipleCmdListApi(props.item.multipleDevice.deviceId);
            })();
          }

          if (props.item.insidePoi !== undefined) {
            setSelectInMove(props.item.insidePoi[0].value);
          }
          if (props.item.outPoi !== undefined) {
            setSelectOutMove(props.item.outPoi[0].value);
          }
          if (props.item.startingFloor !== undefined) {
            setSelectPresetFrom(props.item.startingFloor[0].value1);
          }
          if (props.item.targetFloor !== undefined) {
            setSelectPresetTo(props.item.targetFloor[0].value1);
          }
          break;
        }
        default: {
          setElevatorList(elvList);
          break;
        }
      }
    }
  }, [props.item]);

  useEffect(() => {
    switch (props.action) {
      case 'MOVE': {
        if (selectRobotCmdList && selectRobotCmdList.length > 0) {
          selectRobotCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_MOVE') {
              makePoiList(data.poiList, props.action);
            }
          });
        }
        break;
      }

      case 'CLEAN': {
        if (selectRobotCmdList && selectRobotCmdList.length > 0) {
          selectRobotCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_CLEAN') {
              (async () => {
                await makeCleaningDataList(data.executableTasks, 'task');
                await makeCleaningDataList(data.cleanModes, 'mode');
              })();
            }
          });
        }
        break;
      }

      case 'HALL_CALL': {
        if (selectElvCmdList && selectElvCmdList.length > 0) {
          selectElvCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_HALL_CALL') {
              makeElvFloorList(data.elvFloorList, props.action, null);
            }
          });
        }
        break;
      }
      case 'CAR_CALL': {
        if (selectElvCmdList && selectElvCmdList.length > 0) {
          selectElvCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_CAR_CALL') {
              makeElvFloorList(data.elvFloorList, props.action, null);
            }
          });
        }
        break;
      }
      case 'CHANGE_FLOOR': {
        if (selectElvCmdList && selectElvCmdList.length > 0) {
          selectElvCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_CAR_CALL') {
              makeElvFloorList(data.elvFloorList, props.action, null);
            }
          });
        }
        break;
      }
      case 'ROBOT ELV MOVE': {
        if (selectElvCmdList && selectElvCmdList.length > 0) {
          selectElvCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_HALL_CALL') {
              makeElvFloorList(data.elvFloorList, props.action, 'HC');
            }
            if (data.command === 'COMMAND_CAR_CALL') {
              makeElvFloorList(data.elvFloorList, props.action, 'CC');
            }
          });
        }

        if (selectRobotCmdList && selectRobotCmdList.length > 0) {
          selectRobotCmdList.forEach((data: any, i: number) => {
            if (data.command === 'COMMAND_MOVE') {
              makePoiList(data.poiList, props.action);
            }
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [selectRobotCmdList, selectElvCmdList, props.action]);

  const makeElvFloorList = (data: any, action: string, type: string | null) => {
    if (action === 'HALL_CALL') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.floorIdx;
        item['label'] = item.floorName;
      });
      setElvHcFloorList(data);
    }

    if (action === 'CAR_CALL') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.floorIdx;
        item['label'] = item.floorName;
      });
      setElvCcFloorList(data);
    }

    if (action === 'CHANGE_FLOOR') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.floorIdx;
        item['label'] = item.floorName;
      });
      setElvCcFloorList(data);
    }

    if (action === 'ROBOT ELV MOVE') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.floorIdx;
        item['label'] = item.floorName;
      });
      if (type === 'HC') {
        setPresetElvHcList(data);
      }
      if (type === 'CC') {
        setPresetElvCcList(data);
      }
    }
  };

  const makePoiList = (data: any, action: string) => {
    data.forEach((item: any, i: number) => {
      item['value'] = item.id;
      item['label'] = item.name;
    });

    console.log('move data = ', data);

    // if (action === 'MOVE') {
    setMoveList(data);
    // }

    // if (action === 'ROBOT ELV MOVE') {
    //   console.log('makePoiList action= ', action);
    //
    //   setInMoveList(data);
    //   setOutMoveList(data);
    // }

    //setMove(props.item?.variable[0]?.value);
  };

  const makeCleaningDataList = async (data: any, type: string) => {
    if (type === 'task') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.name;
        item['label'] = item.name;
      });
      setCleanTaskList(data);
    }
    if (type === 'mode') {
      data.forEach((item: any, i: number) => {
        item['value'] = item.name;
        item['label'] = item.name;
      });
      setCleanModeList(data);
    }
  };

  const onChangeFloorInputData = (e: any, id: string) => {
    const value = e.target.value;
    setChangeFloorInputData(value);

    const data = [{ key: props.action, value: value }];

    nodeDataMapping(id, data, 'CHANGE_FLOOR', 'variable');
  };

  const onInputData = (e: any, id: string) => {
    const value = e.target.value;
    setInputData(value);

    const data = [{ key: props.action, value: value }];

    nodeDataMapping(id, data, 'WAIT', 'variable');
  };

  const onDateTime = (dateTime: any, dateTimeString: any, id: string) => {
    setWorkDate(dateTime);
    setWorkDateStr(dateTimeString);

    const data = [{ key: props.action, value: dateTimeString }];

    nodeDataMapping(id, data, 'ON_TIME', 'variable');
  };

  // const resetValue = (id: string, action: string) => {
  //   const newNodeList = JSON.parse(JSON.stringify(nodeList));
  //
  //   newNodeList.forEach((nds: any, i: number) => {
  //     if (nds.id === id) {
  //       if (action === 'MOVE') {
  //         if (Object.prototype.hasOwnProperty.call(nds, 'variable')) {
  //           delete nds.variable;
  //         }
  //       }
  //       if (action === 'CLEAN') {
  //         if (Object.prototype.hasOwnProperty.call(nds, 'task')) {
  //           delete nds.task;
  //         }
  //         if (Object.prototype.hasOwnProperty.call(nds, 'mode')) {
  //           delete nds.mode;
  //         }
  //       }
  //     }
  //   });
  //
  //   console.log('resetValue = ', newNodeList);
  //
  //   changeNodeList(newNodeList);
  // };

  const onSelectDevice = (value: string, id: string, action: string) => {
    setMultipleSelectRobot(value);

    // resetValue(id, action);

    if (action === 'MOVE') {
      setMove(null);
      setMoveList([]);
    }
    if (action === 'CLEAN') {
      setCleaningMode(null);
      setCleaningTask(null);
      setCleanTaskList([]);
      setCleanModeList([]);
    }
    if (action === 'CHANGE_FLOOR') {
      setChangeFloorInputData('');
    }
    if (action === 'HALL_CALL') {
      setHcFrom(null);
      setHcTo(null);
      setElvHcFloorList([]);
    }
    if (action === 'CAR_CALL') {
      setCcTo(null);
      setElvCcFloorList([]);
    }

    (async () => {
      await getCmdListAPI(value);
      await getMultipleCmdListApi(value);
    })();

    const deviceValue = multipleRobotList.filter(
      (item: any) => item.deviceId === value,
    );

    deviceValue[0].uuid = props.deviceGroupUuid;

    nodeDataMapping(id, deviceValue[0], action, 'multipleDevice');
  };

  const nodeDataMapping = (
    id: string,
    data: any,
    action: string,
    key: string,
  ) => {
    const mappingNodeList = JSON.parse(JSON.stringify(nodeList));

    mappingNodeList.forEach((nds: any, i: number) => {
      if (nds.id === id) {
        nds[key] = data;
        if (
          (action === 'MOVE' ||
            action === 'CHANGE_FLOOR' ||
            action === 'CAR_CALL') &&
          key === 'multipleDevice'
        ) {
          if (Object.prototype.hasOwnProperty.call(nds, 'variable')) {
            delete nds.variable;
          }
        }
        if (action === 'CLEAN' && key === 'multipleDevice') {
          if (Object.prototype.hasOwnProperty.call(nds, 'task')) {
            delete nds.task;
          }
          if (Object.prototype.hasOwnProperty.call(nds, 'mode')) {
            delete nds.mode;
          }
        }
        if (action === 'HALL_CALL' && key === 'multipleDevice') {
          if (Object.prototype.hasOwnProperty.call(nds, 'targetFloor')) {
            delete nds.targetFloor;
          }
          if (Object.prototype.hasOwnProperty.call(nds, 'startingFloor')) {
            delete nds.startingFloor;
          }
        }
      }
    });

    // mappingNodeList[0][key] = data;
    changeNodeList(JSON.parse(JSON.stringify(mappingNodeList)));

    // setResultNodeList(mappingNodeList);
  };

  // preset 디바이스 선택
  const onPresetSelectDevice = (value: string, id: string, action: string) => {
    setMultipleSelectRobot(value);

    (async () => {
      await getCmdListAPI(value);
      await getMultipleCmdListApi(value);
    })();

    const deviceValue = presetRobotList.filter(
      (item: any) => item.deviceId === value,
    );

    deviceValue[0].uuid = props.deviceGroupUuid;

    nodeDataMapping(id, deviceValue[0], '', 'multipleDevice');
  };

  const onSelectMove = (value: string, id: string) => {
    setMove(value);
    const data = [{ key: props.action, value: value }];
    nodeDataMapping(id, data, 'MOVE', 'variable');
  };

  /* CHANGE FLOOR */
  const onSelectChangeFloor = (value: string, id: string) => {
    setChangeFloor(value);

    const data = [{ key: props.action, value: value }];
    nodeDataMapping(id, data, 'MOVE', 'variable');

    (async () => {
      await getCmdListAPI(value);
    })();
  };

  /* CLEAN */
  const onSelectTask = (value: string, option: any, id: string) => {
    setCleaningTask(value);
    const data = [{ key: props.action, value: value, data: option }];
    nodeDataMapping(id, data, 'CLEAN', 'task');
  };
  /* CLEAN */
  const onSelectMode = (value: string, id: string) => {
    setCleaningMode(value);
    const data = [{ key: props.action, value: value }];
    nodeDataMapping(id, data, 'CLEAN', 'mode');
  };

  const onSelectHcElv = (value: string, id: string) => {
    setSelectElvDevice(value);
    const data = [{ key: 'selectElv', value: value }];

    nodeDataMapping(id, data, 'HALL_CALL', 'selectElv');

    (async () => {
      await getCmdListAPI(value);
    })();
  };
  const onSelectCcElv = (value: string, id: string) => {
    setSelectElvDevice(value);
    const data = [{ key: 'selectElv', value: value }];

    nodeDataMapping(id, data, 'CAR_CALL', 'selectElv');

    (async () => {
      await getCmdListAPI(value);
    })();
  };

  const onSelectHcFrom = (value: string, id: string) => {
    setHcFrom(value);
    const data = [{ key: 'startingFloor', value: value }];

    nodeDataMapping(id, data, 'HALL_CALL', 'startingFloor');
  };
  const onSelectHcTo = (value: string, id: string) => {
    setHcTo(value);
    const data = [{ key: 'targetFloor', value: value }];

    nodeDataMapping(id, data, 'HALL_CALL', 'targetFloor');
  };
  const onSelectCcTo = (value: string, id: string) => {
    setCcTo(value);
    const data = [{ key: props.action, value: value }];

    nodeDataMapping(id, data, 'CAR_CALL', 'variable');
  };

  ////////////////// PRESET - ROBOT ELV MOVE ///////////////////

  const onSelectPresetElv = (value: string, id: string) => {
    setSelectPresetElv(value);

    const data = [{ key: 'selectElv', value: value }];

    nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'selectElv');

    (async () => {
      await getCmdListAPI(value);
    })();
  };

  const onSelectInMove = (value: string, id: string) => {
    setSelectInMove(value);

    const data = [{ key: 'insidePoi', value: value }];

    nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'insidePoi');
  };

  const onSelectOutMove = (value: string, id: string) => {
    setSelectOutMove(value);

    const data = [{ key: 'outPoi', value: value }];

    nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'outPoi');
  };

  const onSelectPresetFrom = (value: string, option: any, id: string) => {
    setSelectPresetFrom(value);

    const data = [
      { key: 'startingFloor', value1: value, value2: option.label },
    ];

    nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'startingFloor');
  };

  const onSelectPresetTo = (value: string, option: any, id: string) => {
    setSelectPresetTo(value);

    const data = [{ key: 'targetFloor', value1: value, value2: option.label }];

    nodeDataMapping(id, data, 'ROBOT ELV MOVE', 'targetFloor');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const nodeView = () => {
    // console.log('props = ', props)

    let icon;
    let nodeCustom;

    // switch (props.fullName) {
    switch (props.action) {
      case 'CONVEYOR CONTROL':
      case 'PRESET|CONVEYOR CONTROL': {
        icon = <FcSerialTasks size={15} />;
        break;
      }

      case 'ROBOT ELV MOVE':
      case 'PRESET|ROBOT ELV MOVE': {
        nodeCustom = (
          <>
            <div style={styles.elvValueBox}>
              <span style={styles.nodeCustomText}>엘리베이터</span>
              <Select
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectPresetElv(value, props.id)}
                options={elevatorList}
                value={selectPresetElv}
              />
            </div>
            <div style={styles.elvValueBox}>
              <span style={styles.nodeCustomText}>탑승 엘리베이터안</span>
              <Select
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 60, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectInMove(value, props.id)}
                options={moveList}
                value={selectInMove}
              />
            </div>
            <div style={styles.elvValueBox}>
              <span style={styles.nodeCustomText}>하차 엘리베이터밖</span>
              <Select
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 60, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectOutMove(value, props.id)}
                options={moveList}
                value={selectOutMove}
              />
            </div>
            <div style={styles.nodeValueBox}>
              <span style={styles.nodeCustomText}>탑승</span>
              <Select
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 40, height: 13, fontSize: 9 }}
                onChange={(value, option) =>
                  onSelectPresetFrom(value, option, props.id)
                }
                options={presetElvHcList}
                value={selectPresetFrom}
              />
              <span style={styles.nodeCustomText}>하차</span>
              <Select
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 40, height: 13, fontSize: 9 }}
                onChange={(value, option) =>
                  onSelectPresetTo(value, option, props.id)
                }
                options={presetElvCcList}
                value={selectPresetTo}
              />
            </div>
          </>
        );
        icon = <FcSerialTasks size={15} />;
        break;
      }

      case 'START':
      case 'START/END|START':
        icon = <IoCaretForwardCircle size={15} />;
        break;

      case 'END':
      case 'START/END|END':
        icon = <IoStopCircle size={15} />;
        break;

      /* 공통 */
      case 'USER_SELECT':
        icon = <FaUserCheck size={15} />;
        break;
      case 'WAIT':
        nodeCustom = (
          <div>
            <span style={styles.nodeCustomText}>대기 시간</span>
            <Input
              name={props.id}
              value={inputData}
              id="waitInput"
              onChange={(e) => onInputData(e, props.id)}
              className={'nodrag'}
              size={'small'}
              style={{ width: 40, height: 15, fontSize: 9, outline: 'none' }}
            />
            <span style={styles.nodeCustomText}>초(s)</span>
          </div>
        );
        icon = <BiSolidTimer size={15} />;
        break;
      case 'ON_TIME':
        nodeCustom = (
          <div>
            <span style={styles.nodeCustomText}>예약 일시</span>
            <DatePicker
              name={props.id}
              value={workDate}
              size={'small'}
              className={'nodrag on_time'}
              showTime
              style={{
                width: 90,
                height: 13,
                outline: 'none',
                borderRadius: '4px',
                padding: '5px',
                fontSize: '6px',
              }}
              placeholder={'일시를 선택하세요.'}
              onChange={(dateTime, dateTimeString) =>
                onDateTime(dateTime, dateTimeString, props.id)
              }
            />
          </div>
        );
        icon = <MdEventRepeat size={15} />;
        break;

      /* 로봇 : AMR */
      case 'MOVE':
        nodeCustom = (
          <div className={'mt-1'}>
            <span style={styles.nodeCustomText}>이동 위치</span>
            <Select
              id={props.id}
              className={'nodrag'}
              size={'small'}
              style={{ height: '13px', width: 80, fontSize: '9px' }}
              placeholder={'목적지 선택'}
              suffixIcon={null}
              onChange={(value) => onSelectMove(value, props.id)}
              options={moveList}
              value={move}
            />
            {/*<span style={styles.nodeCustomText}>로 이동</span>*/}
          </div>
        );
        icon = <LuMove size={15} />;
        break;
      case 'CHARGE':
        icon = <RiBattery2ChargeFill size={15} />;
        break;
      case 'CHECK':
        icon = <FaListCheck size={15} />;
        break;
      case 'CHECK_SELECT':
        icon = <MdLibraryAddCheck size={15} />;
        break;
      case 'CHANGE_MAP':
        icon = <FaMapMarkedAlt size={15} />;
        break;
      case 'CHANGE_FLOOR':
        nodeCustom = (
          <>
            {/*<div style={styles.elvValueBox}>*/}
            {/*    <span style={styles.nodeCustomText}>엘리베이터</span>*/}
            {/*    <Select*/}
            {/*        className={"nodrag"}*/}
            {/*        size={"small"}*/}
            {/*        suffixIcon={null}*/}
            {/*        style={{width: 73, height: 13, fontSize: 9}}*/}
            {/*        onChange={(value) => onSelectCcElv(value, props.id)}*/}
            {/*        options={elevatorList}*/}
            {/*        value={selectCcElv}/>*/}
            {/*</div>*/}
            <div className={'mt-1'}>
              <span className={'mr-1 ml-2'}>현재층</span>
              <Input
                name={props.id}
                value={changeFloorInputData}
                id="floorInput"
                onChange={(e) => onChangeFloorInputData(e, props.id)}
                className={'nodrag'}
                size={'small'}
                style={{ width: 80, height: 13, fontSize: 7, outline: 'none' }}
              />
              {/*<Select*/}
              {/*  className={'nodrag'}*/}
              {/*  size={'small'}*/}
              {/*  suffixIcon={null}*/}
              {/*  style={{ width: 80, height: 13, fontSize: 9 }}*/}
              {/*  onChange={(value) => onSelectChangeFloor(value, props.id)}*/}
              {/*  options={changeFloorList}*/}
              {/*  value={changeFloor}*/}
              {/*/>*/}
            </div>
          </>
        );
        icon = <TbStairsUp size={15} />;
        break;
      case 'IO_CONTROL':
        icon = <TbEaseInOutControlPoints size={15} />;
        break;
      case 'CONTENTS_PLAY':
        icon = <MdPermMedia size={15} />;
        break;
      case 'BACK_TO_SCHEDULE':
        icon = <MdCancelScheduleSend size={15} />;
        break;
      case 'CLEAN':
        nodeCustom = (
          <>
            <div className={'mt-1'}>
              <span className={'mr-2 ml-3'}>작업</span>
              <Select
                placeholder={'작업 선택'}
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value, option) =>
                  onSelectTask(value, option, props.id)
                }
                value={cleaningTask}
                options={cleanTaskList}
              />
            </div>
            <div className={'mt-1'}>
              <span style={styles.cleanNodeCustomText}>청소모드</span>
              <Select
                placeholder={'청소모드 선택'}
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectMode(value, props.id)}
                value={cleaningMode}
                options={cleanModeList}
              />
            </div>
          </>
        );
        icon = <MdOutlineCleaningServices size={15} />;
        break;
      case 'HOME':
        icon = <MdHome size={15} />;
        break;

      /* 엘리베이터 */
      case 'HALL_CALL':
        nodeCustom = (
          <>
            {/*<div>*/}
            {/*    <span style={styles.nodeCustomText}>엘리베이터</span>*/}
            {/*    <Select*/}
            {/*        className={"nodrag"}*/}
            {/*        size={"small"}*/}
            {/*        suffixIcon={null}*/}
            {/*        style={{width: 73, height: 13, fontSize: 9}}*/}
            {/*        onChange={(value) => onSelectHcElv(value, props.id)}*/}
            {/*        options={elevatorList}*/}
            {/*        value={selectHcElv}/>*/}
            {/*</div>*/}
            <div className={'mt-1'}>
              <span className={'mr-1 ml-2'}>탑승층</span>
              <Select
                placeholder={'탑승층 선택'}
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectHcFrom(value, props.id)}
                options={elvHcFloorList}
                value={hcFrom}
              />
            </div>
            <div className={'mt-1'}>
              <span className={'mr-1 ml-2'}>하차층</span>
              <Select
                placeholder={'하차층 선택'}
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectHcTo(value, props.id)}
                options={elvHcFloorList}
                value={hcTo}
              />
            </div>
          </>
        );
        icon = <PiElevatorFill size={15} />;
        break;
      case 'CAR_CALL':
        nodeCustom = (
          <>
            {/*<div style={styles.elvValueBox}>*/}
            {/*    <span style={styles.nodeCustomText}>엘리베이터</span>*/}
            {/*    <Select*/}
            {/*        className={"nodrag"}*/}
            {/*        size={"small"}*/}
            {/*        suffixIcon={null}*/}
            {/*        style={{width: 73, height: 13, fontSize: 9}}*/}
            {/*        onChange={(value) => onSelectCcElv(value, props.id)}*/}
            {/*        options={elevatorList}*/}
            {/*        value={selectCcElv}/>*/}
            {/*</div>*/}
            <div className={'mt-1'}>
              <span className={'mr-1 ml-2'}>하차층</span>
              <Select
                placeholder={'하차층 선택'}
                className={'nodrag'}
                size={'small'}
                suffixIcon={null}
                style={{ width: 80, height: 13, fontSize: 9 }}
                onChange={(value) => onSelectCcTo(value, props.id)}
                options={elvCcFloorList}
                value={ccTo}
              />
            </div>
          </>
        );
        icon = <FaElevator size={15} />;
        break;
      case 'OPEN_DOOR':
        icon = <BiDoorOpen size={15} />;
        break;
      case 'KEEP_OPEN':
        icon = <RiDoorClosedLine size={15} />;
        break;
      case 'AUTO_DOOR_OPEN':
        icon = <MdOutlineDoorSliding size={15} />;
        break;
      case 'AUTO_DOOR_CLOSE':
        icon = <MdOutlineDoorSliding size={15} />;
        break;
      case 'SET_VALUE':
        icon = <BsDatabaseFillCheck size={15} />;
        break;
      case 'APP_CONTROL': {
        icon = <RiRemoteControlLine size={15} />;
        break;
      }
      case 'OUT_CONTROL': {
        icon = <TbEaseOutControlPoint size={15} />;
        break;
      }
      case 'APP_STOP': {
        icon = <BsSignStopFill size={15} />;
        break;
      }
      case 'STOP': {
        icon = <AiOutlineStop size={15} />;
        break;
      }
      default:
        icon = <></>;
        nodeCustom = <></>;
        break;
    }
    return { icon, nodeCustom };
  };

  useEffect(() => {
    setSelectRobotType(robotType);
  }, [robotType]);

  const robotDevice = () => {
    // console.log('elv = ', elvList);

    if (
      props.action === 'START' ||
      props.action === 'END' ||
      props.type === 'COMMON'
    ) {
      return;
    } else if (props.type === 'ELEVATOR') {
      return (
        <div className={'mt-1'}>
          <span style={styles.nodeCustomText}>디바이스</span>
          <Select
            placeholder={'디바이스 선택'}
            className={'nodrag'}
            size={'small'}
            suffixIcon={null}
            style={{ width: 80, height: 13, fontSize: 9 }}
            onChange={(value) => onSelectDevice(value, props.id, props.action)}
            options={multipleRobotList}
            value={multipleSelectRobot}
          />
        </div>
      );
    } else if (props.type === 'PRESET') {
      return (
        <div className={'mt-1'}>
          <span style={styles.nodeCustomText}>디바이스</span>
          <Select
            placeholder={'디바이스 선택'}
            className={'nodrag'}
            size={'small'}
            suffixIcon={null}
            style={{ width: 80, height: 13, fontSize: 9 }}
            onChange={(value) =>
              onPresetSelectDevice(value, props.id, props.action)
            }
            options={presetRobotList}
            value={
              selectRobotType === 'single' && robotInfo
                ? robotInfo.name
                : multipleSelectRobot
            }
            disabled={selectRobotType === 'single'}
          />
        </div>
      );
    } else {
      return (
        <div className={'mt-1'}>
          <span style={styles.nodeCustomText}>디바이스</span>
          <Select
            placeholder={'디바이스 선택'}
            className={'nodrag'}
            size={'small'}
            suffixIcon={null}
            style={{ width: 80, height: 13, fontSize: 9 }}
            onChange={(value) => onSelectDevice(value, props.id, props.action)}
            options={multipleRobotList}
            value={
              selectRobotType === 'single' && robotInfo
                ? robotInfo.name
                : multipleSelectRobot
            }
            disabled={selectRobotType === 'single'}
          />
        </div>
      );
    }
  };

  const styles = {
    iconParent: {
      borderRight: '1px solid rgba(0, 0, 0, 0.5)',
      padding: 5,
      paddingRight: 10,
      paddingLeft: 5,
      marginRight: '10px',
      display: 'flex',
    },

    nodeCustomText: {
      marginLeft: 3,
      marginRight: 3,
      fontFamily: 'Gmarket Sans, Medium',
    },

    cleanNodeCustomText: {
      width: 20,
      marginLeft: 3,
      marginRight: 3,
      fontFamily: 'Gmarket Sans, Medium',
    },

    nodeTextBox: {
      fontSize: '7px',
      width: '100%',
    },

    nodeValueBox: {
      display: 'flex',
      alignItems: 'center',
    },

    elvValueBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '3px',
      marginTop: '3px',
    },
  };

  return (
    <>
      <div style={styles.iconParent}>{nodeView().icon}</div>
      <div style={styles.nodeTextBox}>
        {/*<span style={{color: 'red'}}>{props.id}</span>*/}
        <div>{props.type}</div>
        <div
          style={{ textAlign: 'center', fontSize: '9px', fontWeight: 'bold' }}
        >
          {props.title}
        </div>
        <div>{robotDevice()}</div>
        <div>{nodeView().nodeCustom}</div>
      </div>
    </>
  );
  // }
};
