import core from '../../config/axios';
import { completeTaskType, completeTaskParams } from '../../_types/history';

const { isClient } = core;

const getCompleteTaskData = async (
  params: completeTaskParams,
): Promise<completeTaskType[]> => {
  try {
    const res = await isClient.get(`/task/all/complete-list`, { params });
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('완료된 작업 이력을 불러오지 못했습니다.');
  }
};

const getCompleteTaskDataById = async (
  id: number,
): Promise<completeTaskType> => {
  try {
    const res = await isClient.get(`/task/status/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('작업 이력을 불러오지 못했습니다.');
  }
};

const postCompleteTaskErrorStatement = async (taskData: completeTaskType) => {
  try {
    const res = await isClient.post(`/task/status/error-statement`, taskData);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('작업이력 에러로그 저장에 실패했습니다.');
  }
};

export {
  getCompleteTaskData,
  getCompleteTaskDataById,
  postCompleteTaskErrorStatement,
};
