import { br } from '@fullcalendar/core/internal-common';

export const NodeEdgeValidation = (nodeList: any, edgeList: any) => {
  const isValidation = false;
  const msg = '';

  const output = nodeList.filter(
    (item: any, idx: number) => item.type === 'output',
  );
  const input = nodeList.filter(
    (item: any, idx: number) => item.type === 'input',
  );

  if (input.length === 0 && output.length === 0) {
    return { isValidation: true, msg: 'START/END 노드가 없습니다.' };
  } else if (output.length === 0) {
    return { isValidation: true, msg: 'END 노드가 없습니다.' };
  } else if (input.length === 0) {
    return { isValidation: true, msg: 'START 노드가 없습니다.' };
  } else {
    const nodeId: any[] = [];
    const edgeId: any[] = [];

    nodeList.filter((item: any, idx: number) => {
      if (item.type !== 'output') {
        nodeId.push(item.id);
      }
    });

    edgeList.filter((item: any, idx: number) => {
      if (item.type !== 'output') {
        edgeId.push(item.source);
      }
    });

    const dup = [...new Set(edgeId)];

    const edgeCheck = nodeId.filter((x) => !dup.includes(x));

    //if (JSON.stringify(dup) !== JSON.stringify(nodeId)) {
    if (edgeCheck.length > 0) {
      return { isValidation: true, msg: '노드연결을 확인하세요.' };
    }
    return { isValidation, msg };
  }
};

export const nodeConditionValidation = (nodeList: any) => {
  let nodeIdList: string[] = [];

  const defaultTypeNode = nodeList.filter(
    (item: any, i: number) => item.type === 'default',
  );

  if (defaultTypeNode.length > 0) {
    defaultTypeNode.forEach((node: any, i: number) => {
      switch (node.data.label.props.action) {
        case 'CHANGE_FLOOR':
        case 'MOVE':
        case 'ON_TIME':
        case 'WAIT': {
          if (
            !node.variable ||
            !node.variable[0].value ||
            node.variable?.length === 0
          ) {
            nodeIdList.push(node.id);
          }

          break;
        }

        case 'CHECK': {
          if (
            !node.condition ||
            node.condition?.commonValue?.timeout === '' ||
            node.condition?.conditionList?.length === 0
          ) {
            nodeIdList.push(node.id);
          }

          break;
        }

        case 'CHECK_SELECT':
        case 'USER_SELECT': {
          if (!node.condition || node.conditionCommon?.timeout === '') {
            nodeIdList.push(node.id);
          }
          break;
        }

        case 'CHANGE_MAP': {
          if (!node.condition) {
            nodeIdList.push(node.id);
          }
          break;
        }

        case 'OUT_CONTROL': {
          // conditionList 체크
          if (!node.condition || node.condition?.conditionList?.length === 0) {
            nodeIdList.push(node.id);
          }

          break;
        }

        case 'APP_CONTROL': {
          if (!node.condition || !node.condition?.inputValue?.appControl) {
            nodeIdList.push(node.id);
          }

          break;
        }

        case 'CLEAN': {
          if (!node.mode || !node.task) {
            nodeIdList.push(node.id);
          }

          break;
        }

        case 'CAR_CALL': {
          if (
            !node.variable ||
            node.variable.length === 0 ||
            node.variable[0].value === '' ||
            !node.condition?.inputValue?.groupId ||
            !node.condition?.inputValue?.carId
          ) {
            nodeIdList.push(node.id);
          }
          break;
        }
        case 'HALL_CALL': {
          if (
            !node.targetFloor ||
            node.targetFloor?.length === 0 ||
            !node.condition?.inputValue?.groupId ||
            !node.condition?.inputValue?.carId
          ) {
            nodeIdList.push(node.id);
          }

          break;
        }
        case 'KEEP_OPEN':
        case 'OPEN_DOOR': {
          if (
            !node.condition?.inputValue?.groupId ||
            !node.condition?.inputValue?.carId
          ) {
            nodeIdList.push(node.id);
          }
          break;
        }
      }
    });
  }

  return nodeIdList; // node id를 리턴
};
