import React, { useEffect, useState } from 'react';
//hall call
//car call
import { IoMoveSharp } from 'react-icons/io5';
import { GoWorkflow } from 'react-icons/go';
import useWorkflowStore from '../../store/workflowStore';
import { getTaskNodeList } from '../../_api/rest/workflow';
import errorPage from '../../pages/ErrorPage';
import useUserStore from '../../store/userStore';
/*SVG*/

const ItemTask = (props: any) => {
  const { robotInfo, robotType } = useWorkflowStore();
  const { selectedUserAuthSite } = useUserStore();

  const [nodeList, setNodeList] = useState<any>([]);

  const [selectTaskId, setSelectTaskId] = useState<string>('');

  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const onDragStart = (event: any, nodeType: any, taskId: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.effectAllowed = 'move';

    setSelectTaskId(taskId);
  };

  useEffect(() => {
    if (props.reRender) {
      setSelectTaskId('');
    }
  }, [props.reRender]);

  useEffect(() => {
    if (props.isReset) {
      setNodeList([]);
      setSelectTaskId('');
    }
  }, [props.isReset]);

  useEffect(() => {
    setNodeList([]);
  }, [robotType]);

  useEffect(() => {
    props.loadingStatus('task', apiLoading);
  }, [apiLoading]);

  useEffect(() => {
    if (robotType === 'single' && selectedUserAuthSite[0] && robotInfo) {
      setApiLoading(true);

      (async () => {
        await getTaskNodeListAPI(selectedUserAuthSite[0], robotInfo.deviceId);
      })();
    }
    if (robotType === 'multiple' && selectedUserAuthSite[0]) {
      setApiLoading(true);

      (async () => {
        await getTaskNodeListAPI(selectedUserAuthSite[0], '');
      })();
    }

    if (props.reRender) {
      props.resetReRender(false, 'task');
    }
  }, [robotType, selectedUserAuthSite, robotInfo, props.reRender]);

  /* 단일로봇 */
  const getTaskNodeListAPI = async (siteId: string, deviceId: string) => {
    await getTaskNodeList(siteId, deviceId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          setNodeList(res);
          setApiLoading(false);
        }
      })
      .catch((error) => {
        errorPage();
      });
  };

  return (
    <aside className={'wf-body-left-contents'}>
      {/* TASK */}
      {nodeList.length > 0 ? (
        nodeList.map((item: any, idx: number) => {
          return (
            <div
              key={`node_${idx}`}
              id={`TASK|${item.taskId}`}
              draggable={true}
              onDragStart={(event) =>
                onDragStart(event, 'default', item.taskId)
              }
            >
              <div
                style={styles.parent}
                className={
                  selectTaskId === item.taskId
                    ? 'border-[1px] border-[#26bfa1] rounded-[4px]'
                    : 'border-[1px] border-[#dddddd] rounded-[4px]'
                }
              >
                <div style={styles.iconParent}>
                  <GoWorkflow size={20} />
                </div>
                {item.taskName}
              </div>
            </div>
          );
        })
      ) : (
        <div className={'text-gray-400 text-[14px]'}>
          등록된 TASK 가 없습니다.
        </div>
      )}
    </aside>
  );
};

export default ItemTask;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: '#fff',
    cursor: 'move',
    marginTop: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Gmarket Sans, Medium',
  },
  iconParent: {
    borderRight: '1px solid rgb(230, 230, 230)',
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: 'rotate(90deg)',
  },
  joinIcon: {
    transform: 'rotate(-90deg)',
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '10px',
    lineHeight: 'normal',
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
